

.containerStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 45px;
    width: 170px;
    padding: 10px;
}