

.searchBarStyle {
    margin-top: 3vw;
    margin-left: 50px;
}

.tableStyle {
    margin-top: 3vw;
}

.tableHead {
    background-color: #0F1841;
}


.tableHead .MuiTableCell-root {
    color: white;
}

.scrollableTable {
    max-height: 100%; /* Ajusta la altura máxima según lo necesites */
    overflow-y: auto;
}


.viewBansContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
}

.bansTableStyle {
    width: 90%;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 2vh;
}

.containerButtonDeleteBan {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 150px;
}

.buttonDeleteBan {
    padding: 7px 15px;
    border-radius: 30px;
    border: none;
    background-color: #CC0711;
    color: #FFFFFF;
    cursor: pointer;
    margin-left: auto;
}
