
.viewHolidaysContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
  }

.holidaysTableStyle {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 3vh;
    width: 90%;
}

.holidaysAddButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-top: 30px;
  margin-left: 50px;
}

.list-item {
  display: flex;
  justify-content: space-between;
}

.list-item-status {
  display: flex;
  align-items: start;
}
