.containerRating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.titleRating {
    font-weight: 700;
    font-size: 1.75vw;
    letter-spacing: 1px;
    color: #000000;
}

.rate {
    color: #0F1841;
    font-size: 2.3vw;
    margin-top: 1.45vw;
    margin-bottom: 5.5vw;
}

.textConfirmRating {
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

/*.selectRating {
    width: 31vw;
    height: 3.75vw;
    margin-top: 2.6vw;
    border: 0.2px solid #595959;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
    outline: none;
}*/

.containerRadioButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.blockRadioButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blockRadioButtonsRight {
    margin-left: 5vw;
}

.containerButtonRating {
    width: 16vw;
    margin-top: 4.25vw;
}
