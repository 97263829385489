.containerLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1;
    width: 100%;
    height: 100vh;
}

.logo {
    /*width: 400px;
    height: 90px;*/
    margin-top: 200px;
}

.formLogin {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.rememberPasswordLogin {
    margin-top: 30px;
    font-weight: 600;
    font-size: 13px;
    color: rgba(89, 89, 89, 0.9);
    cursor: pointer;
}
