.generalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

.form-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field {
    margin-top: 10px;
  }


  .imageStyle {
    height: 200px;
    width: 200px;
  }

  .avatarStyle {
     margin-right: 50px;
  }

.containerDropdowns {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    height: 30%;
    max-height: 100%;
}

  .form-fields-dropdowns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }


  .form-field-dropdown{
    margin-right: 1rem;
    margin-left: 1rem;
  }


  .center {
    width: 200px;
    margin: 0 auto;
  }


  .addStyle {
    margin-right: 50px;
  }

  .formAddPhoto{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  }

