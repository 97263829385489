.description-articles {
    margin-top: 5px;
    width: 100%;
    height: 7.15vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border: 0.5px solid #969696;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
.containerArticlesSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100%;
}
.containerBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    margin-left: 50px;
    max-height: 100%;
}
.left-align {
    margin-top: 30px;
    text-align: left;
}
