.containerRememberPassword {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.containerFirstStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
    height: 450px;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    background-color: #FFFFFF;
}

.logoFirstStep {/*
    width: 170px;
    height: 170px;*/
    margin-top: 26px;
}

.textFirstStep {
    width: 487px;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.75px;
}

.containerButtonsFirstStep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 487px;
}

.containerButtonFS {
    width: 48%;
}

.containerSecondStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
}

.logoRememberPassword {
    /*width: 200px;
    height: 200px;*/
}

.textSecondStep {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.75px;
}

.containerInputsSecondStep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 265px;
    margin-top: 55px;
    margin-bottom: 70px;
}

.containerButtonSecondStep {
    width: 400px;
}
