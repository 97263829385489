.containerGraphics {
    width: 1039px;
    /*width: 600px;
    height: 200px;*/
    margin-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.containerGraphicsBar {
    width: 500px;
    /*width: 600px;
    height: 200px;*/
    margin-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.headerContainerGraphic {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 2.3vw;
    border-radius: 20px 20px 0 0;
    background-color: #0f1841;
}

.textHeaderContainerGraphic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    margin-top: 10px;
    font-weight: 600;
    font-size: 0.75vw;
    color: #FFFFFF;
}

.containerGraphic {
    padding-top: 20px;
    padding-right: 15px;
}
