.containerArticles {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100vh;
}

.titleArticles {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.descriptionArticles {
    margin-top: 0.5vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #030303;
}

.selectArticles {
    width: 11.25vw;
    height: 2.5vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #000000;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    outline: none;
}

.containerItemArticles {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.articleComponent{
    width: 100%;
    max-width: 95%;
}

.sectionAddButton {
    margin-left: 50px;
}
