.containerBlocksChat {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

.blockChatsChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: 100%;
    border-right: 1px solid rgba(89, 89, 89, 0.2);
}

.headerChat {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5.55vw;
    min-height: 5.55vw;
    background-color: #0F1841;
}

.iconBackChat {
    margin-left: 35px;
    cursor: pointer;
}

.containerChatsChat {
    overflow: scroll;
    width: 95%;
    height: 100%;
    padding: 2.5vw 0;
}

.containerChatChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
    background-color: #F5F6FC;
}

.imageUserChat {
    width: 4vw;
    height: 4vw;
    margin-left: 2.65vw;
    cursor: pointer;
}

.nameUserChat {
    margin-left: 1.25vw;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #FFFFFF;
    cursor: pointer;
}

.iconOptionsChat {
    margin-right: 2.5vw;
    margin-left: auto;
    cursor: pointer;
}

.containerChat {
    width: 95%;
    height: 100%;
}

.containerMessagesChat {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 73vh;
    padding-top: 3vw;
}


