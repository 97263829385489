.containerArticle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.containerGoBackArticle {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.iconGoBackArticle {
    width: 0.5vw;
    height: 0.85vw;
}

.goBackArticle {
    margin-left: 0.6vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    text-decoration-line: underline;
    color: #0F1841;
}

.containerTitleArticle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.titleArticle {
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerButtonArticle {
    width: 12vw;
}

.pdfArticle {
    margin-top: 2.05vw;
}

.buttonArticle {
    width: 12vw;
    height: 2.65vw;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 20px;
    background-color: #0F1841;
    font-weight: 600;
    font-size: 0.89vw;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}
