.dotGreen {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #16C83D;
}

.dotRed {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-right: 10px;
    background-color: #CC0711;
}

.stateCircleOcuppied {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-right: 10px;
    background-color: #16C83D;
}