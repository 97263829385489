.generalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

.form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field {
    margin-top: 10px;
  }


  .imageStyle {
    height: 200px;
    width: 200px;
  }

  .avatarStyle {
     margin-right: 50px;
  }


  .center {
    width: 200px;
    margin: 0 auto;
  }


  .addStyle {
    margin-right: 50px;
  }

  .formAddPhoto{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  }

.departmentList {
  display:flex;
  align-items: center;
  margin-bottom: 10px;
}

.trashIcon {
  cursor: pointer;
  margin-left: 10px;
}

.margin-bottom {
  height: 50px;
}
