.generalContainerEditCoach {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
    height: 100vh;
   /*max-height: 100vh;*/
    overflow: scroll;
  }

 .sub-container {
  margin-top: 60px;
  margin-bottom: 60px;
 }

.form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field {
    margin-top: 10px;
  }

  .form-field-table {
    margin-top: 30px;
  }

  .imageStyle {
    height: 200px;
    width: 200px;
  }

  .avatarStyle {
     margin-right: 50px;
    object-fit: cover;
  }

.containerDropdownsEditCoach {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

  .form-fields-dropdowns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }


  .form-field-dropdown{
    margin-right: 1rem;
    margin-left: 1rem;
  }


  .center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100px;
  }


  .addStyle {
    margin-right: 50px;
  }

  .formAddPhoto{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  }

.descripcion-coach {
  margin-top: 40px;
  width: 100%;
  height: 7.15vw;
  padding-top: 1.40vw;
  padding-left: 1.15vw;
  border: 0.5px solid #969696;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  resize: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}


.flexEnd {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.timeButton{
  height: 110px;
  width: 200px;
}

.textTimeTitle {
  justify-content: center;
  justify-items: center;
  font-weight: 500;
  font-size: 18px;
  color: black;
}

.dropdown-container {
  width: 100%;
  margin-bottom: 1rem;
}

.chips-row-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chips-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 15px;
  margin-left: 15px;
  width: 170px;
  height: 100%;
  /* flex-wrap: wrap; */
  /* gap: 0.5rem; */
}

.chip {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  margin-top: 5px;
}

.chip button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  margin-left: 0.25rem;
  outline: none;
}

.chip button:hover {
  color: #c82333;
}


.margin-bottom {
  height: 50px;
}
