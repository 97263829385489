.realContainerInput {
    text-align: left;
    width: 93%;
}

.labelInput {
    margin-left: 7px;
    margin-right: auto;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 15px;
}

.containerInput {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 22px;
    padding-right: 22px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    background-color: #FFFFFF;
}

.input {
    flex: 1;
    width: 100%;
    border: none;
    outline: none;
    margin-left: 10px;
}

.input:focus {
    border: none
}


