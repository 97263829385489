.containerNotFound {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #0F1841;
}

.containerContentNotFound {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleNotFound {
    margin: 0;
    font-weight: 700;
    font-size: 2.4vw;
    text-align: center;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.9);
}

.subtitleNotFound {
    margin: 1.5vw 0 2.1vw 0;
    font-weight: 600;
    font-size: 0.89vw;
    text-align: center;
    letter-spacing: 0.75px;
    color: rgba(255, 255, 255, 0.75);
}

.buttonNotFound {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 1.6vw;
    width: 9.45vw;
    height: 2.80vw;
    background: #CC0711;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 0.8vw;
}
