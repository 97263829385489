.viewRatesContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
}

.ratesTableStyle {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 3vh;
    width: 90%;
}