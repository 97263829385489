.generalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
   width: 100%;
   max-height: 100vh; 
  }

.form-containerSpecialty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center; 
    margin-top: 40px;
    /* margin-left: 40px; */
  }
 
  
  .form-field {
    width: 100%;
    margin-top: 10px;
  }

  .center {
    width: 200px;
    margin: 0 auto;
  }

  .descripcion-coach {
    margin-top: 40px;
    width: 100%;
    height: 7.15vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border-radius: 20px;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    overflow: hidden;
    background-color: #FFFFFF;
  }