.containerReportUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.titleReportUser {
    font-weight: 700;
    font-size: 1.75vw;
    letter-spacing: 1px;
    color: #0F1841;
}

.textReportUser {
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.textareaReportUser {
    width: 50vw;
    height: 12.9vw;
    margin-top: 2.7vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border: 0.5px solid #969696;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    resize: none;
}

.textareaReportUser::placeholder {
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #969696;
}

.containerButtonsReportUser {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 34.8vw;
    margin-top: 4.1vw;
}

.containerButton {
    width: 16vw;
}

