.viewNotificationsContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
}

.notificationsAddButton {
    margin-left: 50px;
}

.notificationsTableStyle {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 1vh;
    width: 90%;
}

