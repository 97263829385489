.inputVerify {
    width: 37px;
    height: 44px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    outline: none;
    background: #FFFFFF;
    text-align: center;
    -webkit-appearance: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}