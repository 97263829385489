
.viewContainer{
  height: 100vw;
  margin-left: 100px;
  margin-right: 100px;
  overflow-y: auto;
}

.userAddButton {
  margin-left: 50px;
}

.searchBarStyle {
  margin-top: 3vw;
  margin-left: 50px;
}

.tableHead {
    background-color: #0F1841;
  }

  .tableHead .MuiTableCell-root {
    color: white;
  }

  .scrollableTable {
    max-height: 250px;
    height: 250px;
    overflow-y: auto;
  }
  .hamburger-menu {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
  }


.context-menu {
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
border-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 1000;
}

.context-menu button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  text-align: left;
  width: 100%;
  outline: none;
}

.context-menu button:hover {
  background-color: #f1f1f1;
}

.context-menu button + button {
  margin-top: 0.25rem;
}

.ant-table-column-sorters svg {
  fill: #ffffff; /* Cambia este valor al color que prefieras */
}

.custom-table .ant-table-thead > tr > th {
  color: white;
  background-color: #0F1841;
}


.marginBot{
  height: 180px;
}


.viewUserContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  overflow-y: auto;
  max-height: 100vh;
 }

 .userTableStyle {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 1vh;
   width: 90%;
}
