.containerUserNavBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin-top: 40px;
}

.imageNavBar {
    width: 5vw;
    height: 5vw;
    border-radius: 100px;
}

.containerTextUserNavBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
}
.nameUserNavBar {
    margin: 8px 0 0 0;
    font-weight: 700;
    font-size: 2vw;
    letter-spacing: 0.75px;
    color: #595959;
}

.emailUserNavBar {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #595959;
}
