.containerMessageSender {
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.containerMessageNoSender {
    display: flex;
    flex-direction: column;
    margin-right: auto;
}

.containerTextSender {
    text-align: left;
    max-width: 90%;
    padding: 1.25vw 1.79vw 1.40vw 1.5vw;
    background-color: #DAE3F3;
    border-radius: 1vw 1vw 0px 1vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.containerTextNotSender {
    text-align: left;
    max-width: 90%;
    padding: 1.25vw 1.5vw 1.40vw 1.79vw;
    background: #D9D9D9;
    border-radius: 1.5vw 1.5vw 1.5vw 0px;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #030303;
}

.timeMessage {
    margin-left: auto;
    margin-top: 0.4vw;
    margin-bottom: 2.94vw;
    font-weight: 400;
    font-size: 0.65vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.containerFiltersStatus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px 0;
}

.containerButtonMessages {
    width: 10vw;
    margin:0 auto;
}
