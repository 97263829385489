body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.containerNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 7px 0 5px -2px rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
}

.containerRouter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  text-align: center;
}

.containerRouterNoNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite .5s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.containerLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1 1;
    width: 100%;
    height: 100vh;
}

.logo {
    /*width: 400px;
    height: 90px;*/
    margin-top: 200px;
}

.formLogin {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.rememberPasswordLogin {
    margin-top: 30px;
    font-weight: 600;
    font-size: 13px;
    color: rgba(89, 89, 89, 0.9);
    cursor: pointer;
}

.realContainerInput {
    text-align: left;
}

.labelInput {
    margin-left: 7px;
    margin-right: auto;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 15px;
}

.containerInput {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 53px;
    padding-left: 22px;
    padding-right: 22px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
    background-color: #FFFFFF;
}

.input {
    flex: 1 1;
    width: 100%;
    border: none;
    outline: none;
    margin-left: 23px;
}

.input:focus {
    border: none
}



.button {
    width: 100%;
    height: 2.65vw;
    /*margin-top: 40px;*/
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 20px;
    background-color: #0F1841;
    font-weight: 600;
    font-size: 0.89vw;
    color: #FFFFFF;
    cursor: pointer;
}

button:disabled {
    background-color: rgba(15, 24, 65, 0.5);
}

.secondaryButton {
    width: 100%;
    height: 2.65vw;
    margin-top: 40px;
    border: 2px solid #0F1841;
    border-radius: 20px;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.89vw;
    color: #0F1841;
    cursor: pointer;
}

.iconButton {
    width: 0.8vw;
    height: 0.75vw;
    margin-right: 0.525vw;
}

.containerRememberPassword {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.containerFirstStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
    height: 450px;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    background-color: #FFFFFF;
}

.logoFirstStep {/*
    width: 170px;
    height: 170px;*/
    margin-top: 26px;
}

.textFirstStep {
    width: 487px;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.75px;
}

.containerButtonsFirstStep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 487px;
}

.containerButtonFS {
    width: 48%;
}

.containerSecondStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
}

.logoRememberPassword {
    /*width: 200px;
    height: 200px;*/
}

.textSecondStep {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.75px;
}

.containerInputsSecondStep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 265px;
    margin-top: 55px;
    margin-bottom: 70px;
}

.containerButtonSecondStep {
    width: 400px;
}

.inputVerify {
    width: 37px;
    height: 44px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    outline: none;
    background: #FFFFFF;
    text-align: center;
    -webkit-appearance: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.containerNotFound {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #0F1841;
}

.containerContentNotFound {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleNotFound {
    margin: 0;
    font-weight: 700;
    font-size: 2.4vw;
    text-align: center;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.9);
}

.subtitleNotFound {
    margin: 1.5vw 0 2.1vw 0;
    font-weight: 600;
    font-size: 0.89vw;
    text-align: center;
    letter-spacing: 0.75px;
    color: rgba(255, 255, 255, 0.75);
}

.buttonNotFound {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 1.6vw;
    width: 9.45vw;
    height: 2.80vw;
    background: #CC0711;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 0.8vw;
}

.containerBlocksChat {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

.blockChatsChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: 100%;
    border-right: 1px solid rgba(89, 89, 89, 0.2);
}

.headerChat {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5.55vw;
    min-height: 5.55vw;
    background-color: #0F1841;
}

.iconBackChat {
    margin-left: 35px;
    cursor: pointer;
}

.containerChatsChat {
    overflow: scroll;
    width: 95%;
    height: 100%;
    padding: 2.5vw 0;
}

.containerChatChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
    background-color: #F5F6FC;
}

.imageUserChat {
    width: 4vw;
    height: 4vw;
    margin-left: 2.65vw;
    cursor: pointer;
}

.nameUserChat {
    margin-left: 1.25vw;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #FFFFFF;
    cursor: pointer;
}

.iconOptionsChat {
    margin-right: 2.5vw;
    margin-left: auto;
    cursor: pointer;
}

.containerChat {
    width: 95%;
    height: 100%;
}

.containerMessagesChat {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 73vh;
    padding-top: 3vw;
}



.containerChatItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 0.594vw;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background: #FFFFFF;
    cursor: pointer;
}

.imageChatItem {
    width: 4.75vw;
    height: 4.75vw;
    margin: 0.95vw 0.70vw 0.95vw 1.55vw;
    border-radius: 100px;
}

.containerTextChatItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1;
}

.nameChatItem {
    margin: 0;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

.companyChatItem {
    margin-top: 0.594vw;
    margin-bottom: 0.4vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.lastMessageChatItem {
    margin: 0;
    max-width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: rgba(89, 89, 89, 0.7);
}

.containerDetailsChatItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: 1.55vw;
}

.timeChatItem {
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #969696;
}

.containerDotQuantityLittleFocus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 100px;
    background-color: #FFFFFF;
    color: #CC0711;
    font-weight: 400;
    font-size: 0.8vw;
}

.containerDotQuantityLittleNoFocus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 100px;
    background-color: #CC0711;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8vw;
}

.containerDotQuantityBig {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1.75vw;
    height: 1.75vw;
    border-radius: 100px;
    background-color: #0F1841;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8vw;
}

.containerWriter {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.inputWriter {
    flex: 1 1;
    height: 3.5vw;
    padding-left: 0.75vw;
    border: none;
    outline: none;
    border-radius: 0.2vw;
    background-color: #F7F7FC;
}

.senderWriter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 3.05vw;
    height: 3.5vw;
    margin-left: 0.44vw;
    border-radius: 4px;
    background-color: #F7F7FC;
    cursor: pointer;
}

.containerMessageSender {
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.containerMessageNoSender {
    display: flex;
    flex-direction: column;
    margin-right: auto;
}

.containerTextSender {
    text-align: left;
    max-width: 90%;
    padding: 1.25vw 1.79vw 1.40vw 1.5vw;
    background-color: #DAE3F3;
    border-radius: 1vw 1vw 0px 1vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.containerTextNotSender {
    text-align: left;
    max-width: 90%;
    padding: 1.25vw 1.5vw 1.40vw 1.79vw;
    background: #D9D9D9;
    border-radius: 1.5vw 1.5vw 1.5vw 0px;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #030303;
}

.timeMessage {
    margin-left: auto;
    margin-top: 0.4vw;
    margin-bottom: 2.94vw;
    font-weight: 400;
    font-size: 0.65vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.containerFiltersStatus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px 0;
}

.containerButtonMessages {
    width: 10vw;
    margin:0 auto;
}

.containerUserCard {
    position: absolute;
    top: 5.55vw;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw;
    height: 88vh;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
}

.iconCloseUserCard {
    width: 1.25vw;
    height: 1.25vw;
    margin-top: 1.25vw;
    margin-right: 1.85vw;
    margin-left: auto;
    cursor: pointer;
}

.imageUserCard {
    width: 8vw;
    height: 8vw;
    border-radius: 100px;
}

.nameUserCard {
    margin: 0.8vw 0;
    font-weight: 700;
    font-size: 1.25vw;
    text-align: center;
    letter-spacing: 1px;
    color: #526581;
}

.companyUserCard {
    font-weight: 600;
    font-size: 1vw;
    text-align: center;
    letter-spacing: 0.75px;
    color: #526581;
}

.titleUserCard {
    margin: 0.8vw 0 0.1vw 0;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #CC0711;
}

.textDetailsUserCard {
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #526581;
}

.containerMenuChat {
    position: absolute;
    top: 4.2vw;
    right: 2.3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12.5vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
}

.itemMenuChat {
    width: 100%;
    cursor: pointer;
}

.textItemMenu {
    margin: 1.1vw 2.3vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.border {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.halfBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.containerRating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.titleRating {
    font-weight: 700;
    font-size: 1.75vw;
    letter-spacing: 1px;
    color: #000000;
}

.rate {
    color: #0F1841;
    font-size: 2.3vw;
    margin-top: 1.45vw;
    margin-bottom: 5.5vw;
}

.textConfirmRating {
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

/*.selectRating {
    width: 31vw;
    height: 3.75vw;
    margin-top: 2.6vw;
    border: 0.2px solid #595959;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
    outline: none;
}*/

.containerRadioButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.blockRadioButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blockRadioButtonsRight {
    margin-left: 5vw;
}

.containerButtonRating {
    width: 16vw;
    margin-top: 4.25vw;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1vw;
}

.textRadioButton {
    margin: 0 0 0 0.5vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.containerReportUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.titleReportUser {
    font-weight: 700;
    font-size: 1.75vw;
    letter-spacing: 1px;
    color: #0F1841;
}

.textReportUser {
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.textareaReportUser {
    width: 50vw;
    height: 12.9vw;
    margin-top: 2.7vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border: 0.5px solid #969696;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    resize: none;
}

.textareaReportUser::placeholder {
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #969696;
}

.containerButtonsReportUser {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 34.8vw;
    margin-top: 4.1vw;
}

.containerButton {
    width: 16vw;
}


.containerArticles {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100vh;
}

.titleArticles {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.descriptionArticles {
    margin-top: 0.5vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #030303;
}

.selectArticles {
    width: 11.25vw;
    height: 2.5vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #000000;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    outline: none;
}

.containerItemArticles {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.articleComponent{
    width: 100%;
    max-width: 95%;
}

.sectionAddButton {
    margin-left: 50px;
}

.addButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    margin-top: 17px;
    cursor: pointer;
}

.iconMenuItem {
    width: 1.4vw;
    margin-left: 18px;
}


.textItem {
    text-align: center;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 600;
    /* font-size: 12px; */
    font-size: 0.55vw;
    letter-spacing: 0.75px;
}
.containerCompleteArticleComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 99%;
}

.containerArticleComponent {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5vw;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.dateArticleComponent {
    margin-top: 0.45vw;
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #969696;
}

.containerDetailsArticleComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.95vw 1.825vw 0.95vw 1.35vw;
}

.detailsArticleComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.detailsDateArticleComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 4vw;
    margin-left: auto;
}

.titleArticleComponent {
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
    margin: 0;
}

.descriptionArticleComponent {
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
    margin: 0.5vw 0 0 0;
}

.iconArticleComponent {
    width: 1.1vw;
    height: 0.8vw;
}

.containerSubarticlesArticlesComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    margin-top: 0.65vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    cursor: pointer;
}

.containerIconsArtcileComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.subarticleArticleComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.lastSubarticleArticleComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.subarticleTextArticleComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.1vw;
    width: 90%;
}

.titleSubarticleArticleComponent {
    margin: 0.287vw 0 0.578vw 0;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.subtitleSubarticleArticleComponent {
    margin: 0 0 0.6vw 0;
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.iconSubarticleArticleComponent {
    margin-right: 1.40vw;
}

.addButtonArticleComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    padding: 15px 0;
}

.iconMenuItemArtcileComponent {
    width: 1.4vw;
    margin-left: 18px;
}


.textItemArticleComponent {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 600;
    /* font-size: 12px; */
    font-size: 1vw;
    letter-spacing: 0.75px;
}

.buttonDeleteCategoryArticle {
    padding: 7px 15px;
    border-radius: 30px;
    border: none;
    background-color: #CC0711;
    color: #FFFFFF;
    cursor: pointer;
}

.buttonDeleteArticle {
    margin-right: 20px;
    padding: 7px 15px;
    border-radius: 30px;
    border: none;
    background-color: #CC0711;
    color: #FFFFFF;
    cursor: pointer;
}

.containerArticle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.containerGoBackArticle {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.iconGoBackArticle {
    width: 0.5vw;
    height: 0.85vw;
}

.goBackArticle {
    margin-left: 0.6vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #0F1841;
}

.containerTitleArticle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.titleArticle {
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerButtonArticle {
    width: 12vw;
}

.pdfArticle {
    margin-top: 2.05vw;
}

.buttonArticle {
    width: 12vw;
    height: 2.65vw;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 20px;
    background-color: #0F1841;
    font-weight: 600;
    font-size: 0.89vw;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}

.containerProfile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.titleProfile {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerContentProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}

.containerImageProfile {
    position: relative;
}

.imageProfile {
    width: 9vw;
    height: 9vw;
    border-radius: 100px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
}

.buttonEditImageProfile {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 7vw;
    right: 0.7vw;
    width: 2.1vw;
    height: 2.1vw;
    border-radius: 100px;
    border: none;
    background-color: #0F1841;
}

.iconEdit {
    width: 0.75vw;
    height: 0.75vw;
}

.titleRatingProfile {
    margin: 0.70vw 0 0.96vw 0;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #526581;
}

.rateProfile {
    color: #0F1841;
    font-size: 1.37vw;
}

.containerDataProfile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.titleDataProfile {
    margin: 2vw 0 0.89vw 0;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #526581;
}

.nameProfile {
    margin: 0;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #526581;
}

.containerSpecialitiesProfile {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
}

.textareaProfile {
    width: 100%;
    height: 7.15vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border: 0.5px solid #969696;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    resize: none;
}

.containerButtonProfile {
    width: 16vw;
    margin: 0 auto;
}

.containerSpeciality {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2vw;
    margin-right: 1.25vw;
    padding: 0 0.65vw;
    border-radius: 20px;
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #595959;
    background: #F4F4F4;
}

.containerSearcher {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 72vw;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    background: #F3F3F4;
}

.inputSearcher {
    flex: 1 1;
    height: 3.59vw;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #595959;
    background-color: transparent;
}

.inputSearcher::placeholder {
    font-weight: 600;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #595959;
}

.iconSearchSearcher {
    width: 1.63vw;
    height: 1.63vw;
    margin-right: 0.89vw;
    margin-left: 1.65vw;
}

.iconDownSearcher {
    width: 1vw;
    height: 0.6vw;
    margin-right: 1vw;
}


.viewContainer{
  height: 100vw;
  margin-left: 100px;
  margin-right: 100px;
  overflow-y: auto;
}

.userAddButton {
  margin-left: 50px;
}

.searchBarStyle {
  margin-top: 3vw;
  margin-left: 50px;
}

.tableHead {
    background-color: #0F1841;
  }

  .tableHead .MuiTableCell-root {
    color: white;
  }

  .scrollableTable {
    max-height: 250px;
    height: 250px;
    overflow-y: auto;
  }
  .hamburger-menu {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
  }


.context-menu {
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
border-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 1000;
}

.context-menu button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  text-align: left;
  width: 100%;
  outline: none;
}

.context-menu button:hover {
  background-color: #f1f1f1;
}

.context-menu button + button {
  margin-top: 0.25rem;
}

.ant-table-column-sorters svg {
  fill: #ffffff; /* Cambia este valor al color que prefieras */
}

.custom-table .ant-table-thead > tr > th {
  color: white;
  background-color: #0F1841;
}


.marginBot{
  height: 180px;
}


.viewUserContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  overflow-y: auto;
  max-height: 100vh;
 }

 .userTableStyle {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 1vh;
   width: 90%;
}

.viewRatesContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
}

.ratesTableStyle {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 3vh;
    width: 90%;
}

.searchBarStyle {
    margin-top: 3vw;
    margin-left: 50px;
}

.tableStyle {
    margin-top: 3vw;
}

.tableHead {
    background-color: #0F1841;
}

.tableHead .MuiTableCell-root {
    color: white;
}

.scrollableTable {
    max-height: 100%;
    overflow-y: auto;
}

.coachAddButton {
    margin-left: 50px;
}

.marginBot{
    height: 180px;
}

.viewCoachContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
}

.coachTableStyle {
    width: 90%;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 1vh;
}



.searchBarStyle {
  margin-top: 3vw;
  margin-left: 50px;
}

.tableStyle {
    margin-top: 3vw;
}

.tableHead {
    background-color: #0F1841;
  }


  .tableHead .MuiTableCell-root {
    color: white;
  }

  .scrollableTable {
    max-height: 100%; /* Ajusta la altura máxima según lo necesites */
    overflow-y: auto;
  }
  .companyAddButton {
    margin-left: 50px;
}


.viewCompanyContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  overflow-y: auto;
  max-height: 100vh;
 }

 .companyTableStyle {
     width: 90%;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 1vh;
}


.searchBarStyle {
    margin-top: 3vw;
}

.tableStyle {
    margin-top: 3vw;
}

.tableHead {
    background-color: #0F1841;
}

.tableHead .MuiTableCell-root {
    color: white;
}

.scrollableTable {
    max-height: 450px;
    overflow-y: auto;
}

.specialtyAddButton {
    margin-left: 50px;
}

.viewSpecialtyContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
   }

   .specialtyTableStyle {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 1vh;
       width: 90%;
  }


.viewSchedulesContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
   }

   .schedulesTableStyle {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 10vh;
       width: 90%;
}

.list-item {
    display: flex;
    justify-content: space-between;
  }

  .list-item-status {
    display: flex;
    align-items: start;
  }

.dotGreen {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #16C83D;
}

.dotRed {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-right: 10px;
    background-color: #CC0711;
}

.stateCircleOcuppied {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-right: 10px;
    background-color: #16C83D;
}
.generalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
   width: 100%;
   max-height: 100vh; 
  }

.form-containerSpecialty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center; 
    margin-top: 40px;
    /* margin-left: 40px; */
  }
 
  
  .form-field {
    margin-top: 10px;
  }

  .center {
    width: 200px;
    margin: 0 auto;
  }

  .descripcion-coach {
    margin-top: 40px;
    width: 100%;
    height: 7.15vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    overflow: hidden;
    background-color: #FFFFFF;
  }
.realContainerInput {
    text-align: left;
    width: 93%;
}

.labelInput {
    margin-left: 7px;
    margin-right: auto;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 15px;
}

.containerInput {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 22px;
    padding-right: 22px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    background-color: #FFFFFF;
}

.input {
    flex: 1 1;
    width: 100%;
    border: none;
    outline: none;
    margin-left: 10px;
}

.input:focus {
    border: none
}



.navBar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    padding: 1rem 0 1rem 0;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #ccc;
    z-index: 100;
  }

  .backButton {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 1.2rem;
  cursor: pointer;
}

.iconBackChat {
  width: 30px;
  height: 30px;
  margin-left: 35px;
  cursor: pointer;
}



.textItem {
  text-align: left;
  margin-right: auto;
  margin-left: 20px;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.75px;
}



/* styles.css */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  border: 1px solid #888;
  width: 40%;
  border-radius: 20px; /* Ca Añadir bordes redondeados */
}

.modal-header {
  background-color: #0F1841;
  padding: 10px;
  display: flex; /* Añadir flexbox */
  justify-content: space-between; /* Alinear elementos horizontalmente */
  align-items: center; /* Alinear elementos verticalmente */
}

.modal-title {
  color: white;
  margin: 0;
}

.modal-body {
  background-color: white;
  padding: 20px;
}

.modal-description {
  color: black; /* Cambiar el color de la descripción a negro */
}

.close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.button-modal{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}


.button-container{
  width: 50%;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.containerAvatar {
    position: relative;
}

.dotAvatarConnected {
    position: absolute;
    bottom: 0.35vw;
    left: 3.50vw;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 100px;
    background-color: #16C83D;
}


.avatarStyle {
    height: 150px;
    width: 150px;
    cursor: pointer;
    border-radius: 100px;
}
.generalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

.form-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field {
    margin-top: 10px;
  }


  .imageStyle {
    height: 200px;
    width: 200px;
  }

  .avatarStyle {
     margin-right: 50px;
  }

.containerDropdowns {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    height: 30%;
    max-height: 100%;
}

  .form-fields-dropdowns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }


  .form-field-dropdown{
    margin-right: 1rem;
    margin-left: 1rem;
  }


  .center {
    width: 200px;
    margin: 0 auto;
  }


  .addStyle {
    margin-right: 50px;
  }

  .formAddPhoto{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  }




.containerStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 45px;
    width: 170px;
    padding: 10px;
}
.realContainerInput {
    text-align: left;
}

.labelInput {
    margin-left: 7px;
    margin-right: auto;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 15px;
}

.containerDateInput {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 130px;
    height: 40px;
    padding-left: 22px;
    padding-right: 22px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    background-color: #FFFFFF;
}

.input {
    flex: 1 1;
    border: none;
    outline: none;
    margin-left: 10px;
}

.input:focus {
    border: none
}



.generalAddCoachContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    overflow: scroll;
  }

 .sub-container {
  margin-top: 60px;
  margin-bottom: 60px;
 }

.form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .errorMessage {
    margin: 5px 0 0 0;
    color: #CC0711;
    font-size: 12px;
  }

  .form-field-table {
    margin-top: 30px;
  }

  .imageStyle {
    height: 200px;
    width: 200px;
  }

  .avatarStyle {
     margin-right: 50px;
  }

.containerDropdowns {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

  .form-fields-dropdowns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }


  .form-field-dropdown{
    margin-right: 1rem;
    margin-left: 1rem;
  }


  .center {
    width: 200px;
    margin: 0 auto;
  }


  .addStyle {
    margin-right: 50px;
  }

  .formAddPhoto{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  }

.descripcion-coach {
  margin-top: 40px;
  width: 100%;
  height: 7.15vw;
  padding-top: 1.40vw;
  padding-left: 1.15vw;
  border: 0.5px solid #969696;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  resize: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}


.flexEnd {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.timeButton{
  height: 110px;
  width: 200px;
}

.textTimeTitle {
  justify-content: center;
  justify-items: center;
  font-weight: 500;
  font-size: 18px;
  color: black;
}

.dropdown-container {
  width: 100%;
  margin-bottom: 1rem;
}

.chips-row-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chips-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 15px;
  margin-left: 15px;
  width: 170px;
  /* flex-wrap: wrap; */
  /* gap: 0.5rem; */
}

.chip {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
    margin-top: 5px;
}

.chip button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  margin-left: 0.25rem;
  outline: none;
}

.chip button:hover {
  color: #c82333;
}


.margin-bottom {
  height: 50px;
}

.generalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

.form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .color-fields {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .form-field {
    margin-top: 10px;
  }


  .imageStyle {
    height: 200px;
    width: 200px;
  }

  .avatarStyle {
     margin-right: 50px;
  }


  .center {
    width: 200px;
    margin: 0 auto;
  }


  .addStyle {
    margin-right: 50px;
  }

  .formAddPhoto{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  }

.departmentList {
  display:flex;
  align-items: center;
  margin-bottom: 10px;
}

.trashIcon {
  cursor: pointer;
  margin-left: 10px;
}

.margin-bottom {
  height: 50px;
}

.generalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
   width: 100%;
   max-height: 100vh; 
  }

.form-containerSpecialty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center; 
    margin-top: 40px;
    /* margin-left: 40px; */
  }
 
  
  .form-field {
    width: 100%;
    margin-top: 10px;
  }

  .center {
    width: 200px;
    margin: 0 auto;
  }

  .descripcion-coach {
    margin-top: 40px;
    width: 100%;
    height: 7.15vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border-radius: 20px;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    overflow: hidden;
    background-color: #FFFFFF;
  }
.generalContainerEditCoach {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
    height: 100vh;
   /*max-height: 100vh;*/
    overflow: scroll;
  }

 .sub-container {
  margin-top: 60px;
  margin-bottom: 60px;
 }

.form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field {
    margin-top: 10px;
  }

  .form-field-table {
    margin-top: 30px;
  }

  .imageStyle {
    height: 200px;
    width: 200px;
  }

  .avatarStyle {
     margin-right: 50px;
    object-fit: cover;
  }

.containerDropdownsEditCoach {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

  .form-fields-dropdowns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }


  .form-field-dropdown{
    margin-right: 1rem;
    margin-left: 1rem;
  }


  .center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100px;
  }


  .addStyle {
    margin-right: 50px;
  }

  .formAddPhoto{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  }

.descripcion-coach {
  margin-top: 40px;
  width: 100%;
  height: 7.15vw;
  padding-top: 1.40vw;
  padding-left: 1.15vw;
  border: 0.5px solid #969696;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  resize: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}


.flexEnd {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.timeButton{
  height: 110px;
  width: 200px;
}

.textTimeTitle {
  justify-content: center;
  justify-items: center;
  font-weight: 500;
  font-size: 18px;
  color: black;
}

.dropdown-container {
  width: 100%;
  margin-bottom: 1rem;
}

.chips-row-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chips-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 15px;
  margin-left: 15px;
  width: 170px;
  height: 100%;
  /* flex-wrap: wrap; */
  /* gap: 0.5rem; */
}

.chip {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  margin-top: 5px;
}

.chip button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  margin-left: 0.25rem;
  outline: none;
}

.chip button:hover {
  color: #c82333;
}


.margin-bottom {
  height: 50px;
}

.generalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

.form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field {
    margin-top: 10px;
  }


  .imageStyle {
    height: 200px;
    width: 200px;
  }

  .avatarStyle {
     margin-right: 50px;
  }


  .center {
    width: 200px;
    margin: 0 auto;
  }


  .addStyle {
    margin-right: 50px;
  }

  .formAddPhoto{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  }

.departmentList {
  display:flex;
  align-items: center;
  margin-bottom: 10px;
}

.trashIcon {
  cursor: pointer;
  margin-left: 10px;
}

.margin-bottom {
  height: 50px;
}

.generalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

.form-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field {
    margin-top: 10px;
  }


  .imageStyle {
    height: 200px;
    width: 200px;
  }

  .avatarStyle {
     margin-right: 50px;
  }

.containerDropdowns {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    height: 30%;
    max-height: 100%;
}

  .form-fields-dropdowns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }


  .form-field-dropdown{
    margin-right: 1rem;
    margin-left: 1rem;
  }


  .center {
    width: 200px;
    margin: 0 auto;
  }


  .addStyle {
    margin-right: 50px;
  }

  .formAddPhoto{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  }


.containerBlocksChat {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

.blockChatsChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: 100%;
    border-right: 1px solid rgba(89, 89, 89, 0.2);
}

.headerChat {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5.55vw;
    min-height: 5.55vw;
    background-color: #0F1841;
}

.iconBackChat {
    margin-left: 35px;
    cursor: pointer;
}

.containerChatsChat {
    overflow: scroll;
    width: 95%;
    height: 100%;
    padding: 2.5vw 0;
}

.containerChatChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
    background-color: #F5F6FC;
}

.containerImageUserChat {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 4vw;
    height: 4vw;
    border-radius: 100px;
    margin-left: 2.65vw;
    overflow: hidden;
}

.imageUserChat {
    width: 115%;
    height: 115%;
    cursor: pointer;
    object-fit: contain;
}

.nameUserChat {
    margin-left: 1.25vw;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #FFFFFF;
    cursor: pointer;
}

.iconOptionsChat {
    margin-right: 2.5vw;
    margin-left: auto;
    cursor: pointer;
}

.containerChat {
    width: 95%;
    height: 100%;
}

.containerMessagesChat {
    overflow: scroll;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 73vh;
    padding-top: 3vw;
}

.containerFilesChat {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 73vh;
    padding-top: 1vw;
}

.iconCloseChat {
    max-width: 1.25vw;
    max-height: 1.25vw;
    margin-bottom: 3vw;
    margin-left: auto;
}

.imageFile {
    width: 35vw;
    height: 90%;
    margin-right: auto;
    margin-left: auto;
}

.containerDiary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.containerHeaderDiary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 2vw 0 1.80vw 0;
}

.selectDiary {
    width: 9.1vw;
    height: 2.6vw;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #000000;
    outline: none;
}

.optionDiary {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.workDiary {
    width: 0.75vw;
    height: 0.75vw;
    margin-right: 0.45vw;
    border-radius: 100px;
    background-color: #0F1841;
}

.notWorkDiary {
    width: 0.75vw;
    height: 0.75vw;
    margin-right: 0.45vw;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #FFFFFF;
}

.partyDiary {
    width: 0.75vw;
    height: 0.75vw;
    margin-right: 0.45vw;
    border-radius: 100px;
    background-color: #B29696;
}

.textOptionDiary {
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #526581;
}

.calendar {
    width: 100%;
    height: 80vh;
}

.invalid {
    background-color: #CC0711;
}

.containerGeneralStats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.titleGeneralStats {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerFiltersGeneralStats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.containerDateFiltersGeneralStats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

.containerCardsGeneralStats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.containerCardStats {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 23%;
    height: 4.9vw;
    border-radius: 20px;
    margin: 20px 20px 0 0;
    box-shadow: 0px 3px 17px -4px rgba(0,0,0,0.75);
}

.containerTopCardStats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.titleCardStats {
    font-weight: 600;
    font-size: 1vw;
    color: #0F1841;
}

.containerIconGeneralStats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1.75vw;
    height: 1.75vw;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.containerTextGeneralStats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
}

.textGeneralStats {
    font-weight: 600;
    font-size: 0.65vw;
    color: #0F1841;
}

.iconCardStats {
    width: 20px;
    height: 20px;
}

.containerGraphics {
    width: 1039px;
    /*width: 600px;
    height: 200px;*/
    margin-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.containerGraphicsBar {
    width: 500px;
    /*width: 600px;
    height: 200px;*/
    margin-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.headerContainerGraphic {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 2.3vw;
    border-radius: 20px 20px 0 0;
    background-color: #0f1841;
}

.textHeaderContainerGraphic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    margin-top: 10px;
    font-weight: 600;
    font-size: 0.75vw;
    color: #FFFFFF;
}

.containerGraphic {
    padding-top: 20px;
    padding-right: 15px;
}

.containerCoachStats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.titleCoachStats {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerFiltersCoachStats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.containerSearcherCoachStats {
    width: 100%;
    margin:20px 0;
}

.containerDateFiltersCoachStats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

.containerCardsCoachStats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.containerCompaniesStats {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    padding-left: 50px;
    height: 100vh;
}

.titleCompaniesStats {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerFiltersCompaniesStats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.containerDateFiltersCompaniesStats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.containerDateFiltersCompaniesStatsAdmin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

.containerCardsCompaniesStats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.description-articles {
    margin-top: 5px;
    width: 100%;
    height: 7.15vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border: 0.5px solid #969696;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
.containerArticlesSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100%;
}
.containerBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    margin-left: 50px;
    max-height: 100%;
}
.left-align {
    margin-top: 30px;
    text-align: left;
}

.description-articles {
    margin-top: 5px;
    width: 100%;
    height: 7.15vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border: 0.5px solid #969696;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
.containerAddArticles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100%;
}
.containerBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    margin-left: 50px;
    max-height: 100%;
}
.margin {
    margin-top: 30px;
}
.left-align {
    margin-top: 30px;
    text-align: left;
}

.description-articles {
    margin-top: 5px;
    width: 100%;
    height: 7.15vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border: 0.5px solid #969696;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
.containerAddArticles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100%;
}
.containerBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    margin-left: 50px;
    max-height: 100%;
}
.margin {
    margin-top: 30px;
}
.left-align {
    margin-top: 30px;
    text-align: left;
}



.searchBarStyle {
    margin-top: 3vw;
    margin-left: 50px;
}

.tableStyle {
    margin-top: 3vw;
}

.tableHead {
    background-color: #0F1841;
}


.tableHead .MuiTableCell-root {
    color: white;
}

.scrollableTable {
    max-height: 100%; /* Ajusta la altura máxima según lo necesites */
    overflow-y: auto;
}


.viewBansContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
}

.bansTableStyle {
    width: 90%;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 2vh;
}

.containerButtonDeleteBan {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 150px;
}

.buttonDeleteBan {
    padding: 7px 15px;
    border-radius: 30px;
    border: none;
    background-color: #CC0711;
    color: #FFFFFF;
    cursor: pointer;
    margin-left: auto;
}


.viewHolidaysContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
  }

.holidaysTableStyle {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 3vh;
    width: 90%;
}

.holidaysAddButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-top: 30px;
  margin-left: 50px;
}

.list-item {
  display: flex;
  justify-content: space-between;
}

.list-item-status {
  display: flex;
  align-items: start;
}

.viewNotificationsContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
}

.notificationsAddButton {
    margin-left: 50px;
}

.notificationsTableStyle {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 1vh;
    width: 90%;
}


.containerNot {
    
}
.containerNavBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.logoNavBar {
    width: 11.5vw;
    margin-top: 45px;
}

.containerItemsNavBar {
    width: 100%;
}


.containerUserNavBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin-top: 40px;
}

.imageNavBar {
    width: 5vw;
    height: 5vw;
    border-radius: 100px;
}

.containerTextUserNavBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
}
.nameUserNavBar {
    margin: 8px 0 0 0;
    font-weight: 700;
    font-size: 2vw;
    letter-spacing: 0.75px;
    color: #595959;
}

.emailUserNavBar {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #595959;
}

.containerMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    margin-top: 30px;
}


.containeOptions{
    overflow: scroll;
    width: 100%;
    max-height: 700px;
    margin-top: 3vw;
}

.MenuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background-color: #0F1841;
    margin-top: 17px;
    cursor: pointer;
}

.MenuItemStats {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background-color: #0F1841;
    margin-top: 17px;
    cursor: pointer;
}

.MenuItemNoFocus {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #F2F4F6;
    background-color: #FFFFFF;
    cursor: pointer;
}

.MenuItemNoFocusStats {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    border-bottom: 1px solid #F2F4F6;
    background-color: #FFFFFF;
    margin-left: auto;
    cursor: pointer;
}

.iconMenuItem {
    width: 1.4vw;
    margin-left: 18px;
}

.textMenuItemFocus {
    text-align: left;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 600;
    font-size: 0.95vw;
    letter-spacing: 0.75px;
    color: #FFFFFF;
}

.textMenuItemNoFocus {
    text-align: left;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 600;
    font-size: 0.95vw;
    letter-spacing: 0.75px;
    color: #526581;
}



.side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background-color: #f8f9fa;
    overflow-y: auto;
  }

  .side-menu nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .side-menu nav ul li {
    padding: 10px;
  }

  .side-menu nav ul li button {
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    color: #000;
    text-decoration: none;
  }

  .side-menu nav ul li button:hover,
  .side-menu nav ul li button:focus {
    outline: none;
    font-weight: bold;
  }


  .menuOptions {
      width: 80%;
    margin-left: 20px;
  }

  .optionsSeparator {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #F2F4F6;
    background-color: #FFFFFF;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    border-top: 0.5px solid rgba(0, 0, 0, 0.2);
    justify-content: start;
  }

  .textSeparator {
    text-align: left;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 600;
    font-size: 1.2vw;
    letter-spacing: 0.75px;
    color: #526581;
}

.containerCloseSesion {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    /* height: 50px; */
    margin-top: auto;
    background-color: #0F1841;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #FFFFFF;
    cursor: pointer;
    padding: 20px;
}

.iconCloseSesion {
    width: 1.05vw;
    height: 1.09vw;
}

